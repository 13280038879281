import * as React from "react"
import { graphql } from "gatsby"
import "../styles/home.css"

//bring graphql data from the page query
// function parseStringToObject(str) {
//   const regex = /^([\w\s]+) - (\w+) \((\d+)\)$/
//   const matches = str.match(regex)

//   if (matches === null) {
//     return null // string does not match expected format
//   }

//   const fullName = matches[1]
//   const type = matches[2].toLowerCase()
//   const age = parseInt(matches[3], 10)

//   return { fullName, age, type }
// }

// seaWorldTix.ticketHolders.ticketHolders returns a string of a group the ticket holders like this: "John Doe - child (5), Jane Doe - child (3), John Doe - parent (NaN), Jane Doe - parent (NaN)". How can I split this string into an array of objects with the name, type, and age of each person?

const SeaWorldTrip = ({ data, fullName, type, age }) => {
  const seaWorldTix = data.allContentfulSeaWorldRegistration.nodes

  return (
    <div className="w-10/12 max-w-[600px] mx-auto py-10">
      <h1 className="text-3xl font-bold">SeaWorld Tickets</h1>
      {seaWorldTix.map((seaWorldTix, index) => (
        <div key={index}>
          <p className="py-2">
            {seaWorldTix.ticketHolders.ticketHolders
              .split(",")
              .filter((item) => !item.includes("- parent (NaN)"))
              //break line on each comma
              .map((item) => (
                <span>
                  {item}
                  <br />
                </span>
              ))}
          </p>
        </div>
      ))}
    </div>
  )
}

export default SeaWorldTrip

export const query = graphql`
  query SeaWorldTix {
    allContentfulSeaWorldRegistration {
      totalCount
      nodes {
        buyerFName
        buyerLName
        totalTickets
        telephone
        ticketHolders {
          ticketHolders
        }
      }
    }
  }
`
